import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from '@react-spring/web';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import { NavigationSectionHeader } from 'managers/Components.js';
import PageControl from 'views/PageControl.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
//import { ViewCardHeader, ViewEntry } from 'views/Main.js';

/*
export const GraciAssistant = ({ input }, { index, options, utils }) => {

    const layerID = 'graci_assistant';
    const scrollViewRef = useRef(null);

    const [height, setHeight] = useState(0);
    const [id, setID] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState(null);

    const onNewOutput = evt => {

        // end loading, set completion id if an id was provided, and update state with messages
        setID(id => evt.id || id);
        setLoading(false);

        // update local state with messages data
        setMessages(messages => {
            let results = messages.filter(message => message.loading !== true);
            if(evt.error) {
                return results.concat([{
                    error: evt.error,
                    role: 'system'
                }]);
            }
            if(evt.messages) {
                return evt.messages.map(message => {
                    if(message.role !== 'user' && message.visible !== false) {
                        try {
                            message.content = JSON.parse(message.content);
                        } catch(e) {
                            console.error(e.message);
                        }
                    }
                    return message;
                });
            }
            return results;
        });
    }

    const onSendMessage = async () => {
        try {

            setLoading('submit');
            if(!text || text.length === 0) {
                throw new Error('Please enter a message before moving on');
            }

            // clear text field for next input
            setText(null);

            // add message for user and system to list of messages, this is later replaced by a server event
            setMessages(messages => {
                return messages.concat([{
                    content: text,
                    role: 'user'
                }]);
            });

            // add system loading event after a brief delay
            await Utils.sleep(0.5);
            setMessages(messages => {
                return messages.concat([{
                    loading: true,
                    role: 'system'
                }]);
            });

            // emit a new message input event to the websockets server
            utils.sockets.emit('graci', 'new_chat_completion_message_input', {
                id: id,
                messages: [text],
                session: getSessionVariables()
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Just a Second',
                message: e.message || 'An unknown error occurred'
            });
        }
    }

    const onShowChatHistory = () => {
        utils.layer.open({
            Component: GraciAssistantChatHistory.bind(this, {
                onChange: chat => {
                    setID(chat.id);
                    setMessages(chat.metadata.messages || []);
                }
            }),
            id: 'graci_assistant_chat_history'
        });
    }

    const getMessages = () => {

        // prepare list of message targets
        let targets = messages.filter(message => message.visible !== false && message.role !== 'tool');
        if(targets.length === 0) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: height,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/graci-ai-spinner.json')}
                    style={{
                        height: 150,
                        marginBottom: 12,
                        width: 150
                    }}/>
                    <div style={{
                        backgroundColor: Appearance.colors.softBorder(),
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 12,
                        marginBottom: 8,
                        padding: '10px 16px 10px 16px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.header()
                        }}>{`Hi, I'm GRACI`}</span>
                    </div>
                    <div style={{
                        backgroundColor: Appearance.colors.softBorder(),
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 12,
                        marginBottom: 4,
                        padding: '12px 16px 12px 16px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>
                            {'I can anwswer questions about your home safe network and help you troubleshoot issues with your sensors.'}
                        </span>
                    </div>
                </div>
            )
        }

        return targets.map((message, index) => {
            return (
                <Message 
                data={message} 
                key={index}
                utils={utils} />
            )
        });
    }

    const getSessionVariables = () => {
        let dealership = utils.dealership.get();
        return {
            dealership_id: dealership.id,
            dealership_name: dealership.name,
            platform: 'web'
        }
    }

    const getTextInput = () => {

        let canSendMessage = loading === 'submit' || text?.length === 0 ? false : true;
        return (
            <div style={{
                alignItems: 'center',
                borderTopColor: Appearance.colors.divider(),
                borderTopWidth: 1,
                display: 'flex',
                flexDirection: 'row',
                padding: 16,
                width: '100%'
            }}>
                <TextField 
                placeholder={'Ask me anything about your network...'}
                onChange={setText}
                value={text} />
                <div
                className={canSendMessage ? 'text-button' : ''}
                onClick={canSendMessage ? onSendMessage : null}
                style={{
                    backgroundColor: canSendMessage ? Appearance.colors.primary(utils) : Appearance.colors.grey(),
                    borderRadius: 17.5,
                    marginLeft: 8
                }}>
                    <img 
                    src={canSendMessage ? 'images/send-arrow-button-clear.png' : 'images/ai-sending-button-clear.png'}
                    style={{
                        height: 35,
                        width: 35
                    }} />
                </div>
            </div>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('graci', 'new_chat_completion_message_output', onNewOutput);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `We were unable to establish a connection to the GRACI assistant. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('graci', 'new_chat_completion_message_output', onNewOutput);
        } catch(e) {
            console.error(e.message);
        }
    }

    const initCompletionWithCustomInput = () => {

        // set placeholder loading message while request is submitted and processed
        setLoading('submit');
        setMessages([{
            content: { text: input.message },
            role: 'system'
        },{
            loading: true,
            role: 'system'
        }]);

        // submit new input request to server and start chat completion
        utils.sockets.emit('graci', 'new_chat_completion_message_input', {
            instructions: input.instructions,
            session: getSessionVariables()
        });
    }

    useEffect(() => {
        scrollViewRef.current.scrollToEnd({ animated: true });
    }, [messages]);

    useEffect(() => {
        if(input) {
            initCompletionWithCustomInput();
        }
    }, [input]);

    useEffect(() => {
        connectToSockets();
        return disconnectFromSockets;
    }, []);

    return (
        <Layer 
        id={layerID}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            onHeightChange: setHeight,
            removePadding: true,
            secondaryButton: {
                color: Appearance.colors.primary(utils),
                onClick: onShowChatHistory,
                text: 'History'
            },
            sticky: {
                bottom: getTextInput()
            }
        }}
        ref={scrollViewRef}
        utils={utils}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 16,
                width: '100%'
            }}>
                {getMessages()}
            </div>
        </Layer>
    )
}

export const GraciAssistantChatHistory = ({ onChange }, { index, options, utils }) => {

    const layerID = 'graci_assistant_chat_history';
    const limit = 10;
    const offset = useRef(0);

    const [chats, setChats] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const theme = utils.theme.get();

    const onChatClick = async id => {
        try {

            setLoading(id);
            let { chat } = await Request.get(utils, '/users/', {
                id: id,
                type: 'openai_chat_completion_details'
            });
            
            setLayerState('close');
            if(typeof(onChange) === 'function') {
                onChange(chat);
            }
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your the details for this chat. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 50
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    duration={2500}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(chats.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%'
                }}>
                    <ViewEntry 
                    bottomBorder={false}
                    hideIcon={true}
                    subTitle={'We were unable to find any chats for your device'}
                    title={'No Chats Found'} 
                    theme={theme}
                    utils={utils}/>
                </div>
            )
        }
        return chats.map((chat, index) => {
            return (
                <ViewEntry 
                bottomBorder={index !== chats.length - 1}
                icon={{
                    path: require('images/graci-assistant-message-icon.png')
                }}
                key={index}
                loading={loading === chat.id}
                onClick={onChatClick.bind(this, chat.id)}
                subTitle={Utils.format.date(chat.date)}
                title={chat.name} 
                theme={theme}
                utils={utils}/>
            )
        })
    }

    const fetchChats = async () => {
        try {
            let { chats, paging } = await Request.get(utils, '/users/', {
                limit: limit,
                offset: offset.current,
                type: 'openai_chat_completions_history'
            });

            setLoading(false);
            setPaging(paging);
            setChats(chats.map(chat => ({
                ...chat,
                date: moment.utc(chat.date).local()
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your chat history. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchChats();
    }, []);

    return (
        <Layer 
        id={layerID}
        index={index}
        options={{
            ...options,
            bottomCard: true,
            layerState: layerState,
            loading: loading === true
        }}
        utils={utils}>
            <ViewCardHeader
            body={`These are all the chats that you've started for your network. Tap on a chat to view your conversation.`}
            title={'Chat History'} />
            <div style={Appearance.styles.unstyledPanel()}>
                {getContent()}
            </div>
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                loading={loading === 'paging'}
                offset={offset.current}
                style={{
                    borderTopWidth: 0
                }}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchChats();
                }} 
                utils={utils} />
            )}
        </Layer>
    )
}

const Message = ({ data, utils }) => {

    const [animations, animationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 0,
        top: -50
    }));
    const theme = utils.theme.get();

    const onItemClick = item => {
        switch(item.key?.toLowerCase()) {
            case 'address':
            window.open(`https://www.google.com/maps/place/${encodeURIComponent(item.value)}`)
            break;

            case 'email':
            case 'email address':
            window.open(`mailto:${item.value}`);
            break;

            case 'phone':
            case 'phone number':
            window.open(`tel:${item.value}`);
            break;

            case 'website':
            window.open(item.value);
            break;
        }
    }

    const getBackgroundColor = () => {
        if(data.role === 'user') {
            return Appearance.colors.primary(utils);
        }
        if(data.error) {
            return Appearance.colors.red;
        }
        return Appearance.colors.softBorder();
    }

    const getValueColor = item => {
        if(item.value.toLowerCase() === 'offline') {
            return Appearance.colors.red;
        }
        if(item.key.toLowerCase().includes('emergency') === true && item.value.toLowerCase() === 'yes') {
            return Appearance.colors.red;
        }
        if(item.value.toLowerCase() === 'online') {
            return Appearance.colors.green;
        }
        return Appearance.colors.subText();
    }
    

    const getContent = () => {
        if(data.visible === false) {
            return null;
        }
        if(data.error) {
            return getMessageContainer((
                <div style={{
                    padding: '10px 16px 10px 16px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: 'white'
                    }}>{data.error}</span>
                </div>
            ))
        }
        if(data.loading === true) {
            return getMessageContainer((
                <div style={{
                    padding: '4px 16px 4px 16px'
                }}>
                    <LottieView
                    autoPlay={true}
                    duration={2000}
                    loop={true}
                    source={require('files/lottie/dots-grey.json')}
                    style={{
                        height: 35,
                        width: 35
                    }}/>
                </div>
            ))
        }
        if(data.content) {
            if(data.role === 'user') {
                return getMessageContainer((
                    <div style={{
                         padding: '10px 16px 10px 16px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            color: data.role === 'user' ? 'white' : Appearance.colors.text()
                        }}>{data.content || ''}</span>
                    </div>
                ))
            }
            let { data_input_request, sections = [], text } = data.content;
            return (
                <>
                {text?.length > 0 && 
                    getMessageContainer((
                        <div style={{
                            padding: '10px 16px 10px 16px'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: Appearance.colors.text()
                            }}>{text}</span>
                        </div>
                    ))
                }
                {data_input_request?.length > 0 && 
                    getMessageContainer((
                        <div style={{
                            padding: '10px 16px 10px 16px'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: Appearance.colors.text()
                            }}>{data_input_request}</span>
                        </div>
                    ))
                }
                {(sections || []).map((section, index, sections) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            marginBottom: index !== sections.length - 1 ? 8 : 0,
                        }}>
                            {getMessageContainer((
                                <>
                                <div style={{
                                    padding: '10px 16px 10px 16px',
                                    width: '100%'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        marginBottom: 2
                                    }}>{section.header}</span>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle()
                                    }}>{section.sub_header}</span>
                                </div>
                                {Array.isArray(section.list_items) && section.list_items.map((item, index) => {
                                    let pressable = shouldAcceptClicks(item);
                                    return (
                                        <div
                                        className={pressable ? 'text-button' : ''} 
                                        key={index}
                                        onClick={pressable ? onItemClick.bind(this, item) : null}
                                        style={{
                                            alignItems: 'center',
                                            borderBottomColor: Appearance.colors.divider(),
                                            borderBottomWidth: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minWidth: 0,
                                            paddingHorizontal: 16,
                                            paddingVertical: 10,
                                            width: '100%'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 1,
                                                flexShrink: 1,
                                                minWidth: 0
                                            }}>
                                                <span style={{
                                                    ...Appearance.textStyles.key(),
                                                    color: Appearance.colors.text(),
                                                    marginBottom: 2
                                                }}>{item.key}</span>
                                                <span style={{
                                                    ...Appearance.textStyles.key(),
                                                    color: getValueColor(item)
                                                }}>{item.value}</span>
                                            </div>
                                            {pressable === true && (
                                                <img
                                                src={'images/next-arrow-grey-small.png'}
                                                style={{
                                                    height: 12,
                                                    marginLeft: 12,
                                                    opacity: 0.75,
                                                    objectFit: 'contain',
                                                    width: 12
                                                }} />
                                            )}
                                        </div>
                                    )
                                })}
                                </>
                            ))}
                            {(section.video_tutorial_links || []).map((link, index) => {
                                return getMessageContainer((
                                    <ViewEntry 
                                    bottomBorder={false}
                                    icon={{
                                        path: require('images/graci-assistant-message-icon.png')
                                    }}
                                    onClick={() => window.open(link.url)}
                                    subTitle={'Tap to open link in your browser'}
                                    title={link.title}
                                    theme={theme}
                                    utils={utils} />
                                ), index)
                            })}
                        </div>
                    )
                })}
                </>
            )
        }
    }

    const getMessageContainer = (children, key) => {
        return (
            <animated.View 
            key={key}
            style={{
                alignSelf: data.role === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor: getBackgroundColor(),
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                borderTopLeftRadius: data.role === 'user' ? 12 : 0,
                borderTopRightRadius: data.role === 'user' ? 0 : 12,
                marginBottom: 16,
                maxWidth: '75%',
                position: 'relative',
                ...animations
            }}>
                {children}
            </animated.View>
        )
    }

    const shouldAcceptClicks = item => {
        return item.value?.toLowerCase().includes('please provide') === false && ['address','email','email_address','phone','phone number','website'].includes(item.key?.toLowerCase());
    }

    useEffect(() => {
        animationsApi.start({
            opacity: 1,
            top: 0
        });
    }, []);
    
    return getContent();
}
*/

export const GraciSupportContent = ({ assets, style, values = [] }) => {

    const [animations, animationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 0,
        transform: 'scale(0.75)'
    }));
    const [fullscreenContent, setFullscreenContent] = useState(null);
    const [transcriptVisibility, setTranscriptVisibility] = useState({});

    const onCloseFullscreenContentClick = () => {
        animationsApi.start({
            to: async next => {
                await next({
                    opacity: 0,
                    transform: 'scale(0.75)'
                });
                setFullscreenContent(null);
            }
        });
    }

    const onTranscriptVisibilityClick = index => {
        setTranscriptVisibility(props => {
            return update(props, {
                [index]: {
                    $set: true
                }
            });
        });
    }

    const getAssets = () => {
        return assets.map((asset, index) => {
            switch(asset.type) {
                case 'image':
                return (
                    <LayerItem 
                    key={index}
                    lastItem={index === assets.length - 1}
                    spacing={'5vw'}
                    title={asset.title.toUpperCase()}>
                        {asset.values && asset.values.map((value, i) => (
                            <ResourceText 
                            key={i}
                            lastItem={false}
                            {...value} />
                        ))}
                        <div style={{
                            backgroundColor: Appearance.colors.divider(),
                            borderRadius: 10,
                            overflow: 'hidden',
                            position: 'relative',
                            width: '100%'
                        }}>
                            <div style={{
                                paddingBottom: Utils.isMobile() === true ? 12 : '7.5%',
                                paddingLeft: Utils.isMobile() === true ? 12 : (window.innerWidth > 1500 ? '25%' : '15%'),
                                paddingRight: Utils.isMobile() === true ? 12 : (window.innerWidth > 1500 ? '25%' : '15%'),
                                paddingTop: Utils.isMobile() === true ? 12 : '7.5%'
                            }}>
                                <img 
                                src={asset.url} 
                                style={{
                                    height: 'auto',
                                    objectFit: 'contain',
                                    width: '100%'
                                }} />
                            </div>
                            {getFullscreenButton(asset)}
                        </div>
                    </LayerItem>
                )
    
                case 'video':
                return (
                    <LayerItem 
                    key={index}
                    lastItem={index === assets.length - 1}
                    spacing={'5vw'}
                    title={asset.title.toUpperCase()}>
                        {asset.values && asset.values.map((value, i) => (
                            <ResourceText 
                            key={i}
                            lastItem={false}
                            {...value} />
                        ))}
                        <div style={{
                            position: 'relative',
                            width: '100%'
                        }}>
                            <video 
                            autoPlay={false}
                            controls={true}
                            preload={'none'}
                            poster={asset.poster_url}
                            style={{
                                borderRadius: 10,
                                height: 'auto',
                                objectFit: 'contain',
                                width: '100%' 
                            }}>
                                <source 
                                src={asset.url} 
                                type={'video/mp4'} />
                            </video>
                            {getVideoTranscript(index)}
                        </div>
                    </LayerItem>
                )
    
                default:
                return null;
            }
        });
    }

    const getContent = () => {
        
        // prepare text values for panel
        let entries = [];

        // add custom text values from the content entry if applicable
        if(values && values.length > 0) {
            entries = values;
        }

        return entries.elngth > 0 && (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: Utils.isMobile() ? 24 : '2vw',
                width: '100%'
            }}>
                {entries.map((entry, index, entries) => {
                    return (
                        <ResourceText 
                        key={index}
                        lastItem={index === entries.length - 1}
                        {...entry} />
                    )
                })}
            </div>
        )
    }

    const getFullscreenButton = asset => {
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.35)',
                    borderRadius: 10,
                    bottom: 0,
                    padding: 8,
                    position: 'absolute',
                    right: 0
                }}>
                    <img 
                    className={'text-button'}
                    onClick={setFullscreenContent.bind(this, asset)}
                    src={'images/fullscreen-icon-white.png'}
                    style={{
                        height: 25,
                        objectFit: 'contain',
                        width: 25
                    }} />
                </div>
            )
        }
        return (
            <img 
            className={'text-button'}
            onClick={setFullscreenContent.bind(this, asset)}
            src={'images/fullscreen-icon-grey.png'}
            style={{
                bottom: 16,
                height: 30,
                objectFit: 'contain',
                position: 'absolute',
                right: 16,
                width: 30
            }} />
        )
    }

    const getFullscreenContent = () => {
        return fullscreenContent && (
            <animated.div style={{
                alignItems: 'center',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                left: 0,
                opacity: animations.opacity,
                padding: Utils.isMobile() ? 24 : '5vw',
                position: 'fixed',
                right: 0,
                top: 0,
                zIndex: 9990,
                width: '100%'
            }}>
                <div 
                className={'cursor-pointer'}
                onClick={onCloseFullscreenContentClick}
                style={{
                    backgroundColor: 'rgba(0,0,0,0.75)',
                    bottom: 0,
                    height: '100%',
                    left: 0,
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    zIndex: 9991,
                    width: '100%'
                }} />
                <animated.img
                src={fullscreenContent.url}
                style={{
                    borderRadius: 10,
                    height: '100%',
                    maxWidth: 1250,
                    objectFit: 'contain',
                    overflow: 'hidden',
                    transform: animations.transform,
                    zIndex: 9992,
                    width: '100%'
                }} />
                <img
                className={'text-button'}
                src={'images/close-button-white.png'}
                onClick={onCloseFullscreenContentClick}
                style={{
                    height: 30,
                    objectFit: 'contain',
                    position: 'fixed',
                    right: 24,
                    top: 24,
                    zIndex: 9993,
                    width: 35
                }} />
            </animated.div>
        )
    }

    const getVideoTranscript = index => {

        let asset = assets[index];
        if(!asset.transcript) {
            return null;
        }
        if(transcriptVisibility[index] === true) {
            return asset.transcript && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 12,
                    padding: Utils.isMobile() === true ? 24 : '2vw',
                    width: '100%'
                }}>
                    {asset.transcript.map((entry, index, entries) => {
                        if(!entry.link) {
                            return (
                                <span 
                                key={index}
                                style={{
                                    ...Appearance.textStyles.bodyText(),
                                    lineHeight: 1.2,
                                    marginBottom: index !== entries.length - 1 ? Utils.isMobile() === true ? 24 : '1vw' : 0,
                                    whiteSpace: 'normal'
                                }}>{`${index + 1}. ${entry.text}`}</span>
                            )
                        }

                        let components = entry.text.split(entry.link.label);
                        return (
                            <div 
                            key={index}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: index !== entries.length - 1 ? Utils.isMobile() === true ? 24 : '1vw' : 0
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.bodyText(),
                                    lineHeight: 1.2,
                                    whiteSpace: 'normal'
                                }}>
                                    {`${index + 1}. ${components[0]}`}
                                    <span 
                                    className={'text-button'}
                                    key={index}
                                    onClick={window.open.bind(this, entry.link.url)}
                                    style={{
                                        color: Appearance.colors.primary(),
                                        textDecoration: 'underline'
                                    }}>{entry.link.label}</span>
                                    {`${components[1]}`}
                                </span>
                            </div>
                            
                        )
                    })}
                </div>
            )
        }
        return (
            <div 
            className={'row ml-0 mr-0'}
            style={{
                ...Appearance.styles.unstyledPanel(),
                marginTop: 12,
                padding: Utils.isMobile() === true ? 12 : '1vw',
                width: '100%'
            }}>
                <div className={'col-12 col-md-8 col-lg-10 p-1 p-md-0'}>
                    <div 
                    className={'pr-0 pr-md-2'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.layerItemTitle(),
                            lineHeight: 1.2,
                            marginBottom: 4,
                        }}>{('Video Transcript Available').toUpperCase()}</span>
                        <span style={{
                            ...Appearance.textStyles.bodyText(),
                            whiteSpace: 'normal'
                        }}>{'To access the full transcription of the video and read along with the video demonstration, please click the "Transcribe" button, and the text will be displayed for your convenience.'}</span>
                    </div>
                </div>
                <div className={'col-12 col-md-4 col-lg-2 px-0 pt-3 pb-0 p-md-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Button 
                        color={'primary'}
                        label={'Transcribe'}
                        onClick={onTranscriptVisibilityClick.bind(this, index)}
                        type={'large'} />
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if(fullscreenContent) {
            animationsApi.start({
                opacity: 1,
                transform: 'scale(1)'
            });
        }
    }, [fullscreenContent]);

    return (
        <div style={{
            padding: Utils.isMobile() ? 24 : '2vw',
            width: '100%'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                display: 'flex',
                flexDirection: 'column',
                padding: Utils.isMobile() ? 24 : '2vw',
                position: 'relative',
                width: '100%',
                ...style
            }}>
                {getContent()}
                {getAssets()}
                {getFullscreenContent()}
            </div>
        </div>
    )
}

export const Support = ({ dealership, utils }) => {

    const limit = 10;
    const offset = useRef(0);
    const searchTextRef = useRef(null);

    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onManuallyStartSearch = () => {
        offset.current = 0;
        setLoading('search');
        fetchResources();
    }

    const onSearchFieldKeyDown = value => {
        if(value === 'enter') {
            onManuallyStartSearch();
        }
    }

    const onSearchTextChange = text => {

        // reset offset for paging
        offset.current = 0;

        // update search ref and search text state
        searchTextRef.current = text;
        setSearchText(text);

        // fetch resources if all search text has been removed
        if(!text || text.length === 0) {
            setLoading(true);
            fetchResources();
        }
    }

    const onViewMoreResultsClick = () => {
        offset.current = offset.current + limit;
        setLoading('paging');
        fetchResources();
    }

    const getPagingComponents = () => {
        let { current_page = 0, number_of_pages = 0 } = paging || {};
        return current_page < number_of_pages && (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2vw',
                width: '100%'
            }}>
                <Button 
                color={'primary'}
                label={'View More'}
                laoding={loading === 'paging'}
                onClick={onViewMoreResultsClick} 
                type={'large'} />
            </div>
        )
    }

    const getResourcesContent = () => {
        if(loading === true || loading === 'search') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '2vw',
                    maxWidth: 1000,
                    padding: 24,
                    width: '100%'
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }
        if(content.length === 0) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '2vw',
                    maxWidth: 1000,
                    padding: '2vw'
                }}>
                    <img
                    src={window.theme === 'dark' ? 'images/no-resources-found-white.png' : 'images/no-resources-found-black.png'}
                    style={{
                        height: 65,
                        marginBottom: 16,
                        objectFit: 'contain',
                        width: 65
                    }} />
                    <span style={{
                        ...Appearance.textStyles.layerItemTitle(),
                        marginBottom: 8,
                    }}>{('No Resources Found').toUpperCase()}</span>
                    <span style={{
                        ...Appearance.textStyles.bodyText(),
                        textAlign: 'center'
                    }}>{`It looks like no search results were found for your query. This can happen if the terms you used are too specific or if there aren't any articles or tutorials that match your request. We recommend trying different keywords or phrases to broaden your search.`}</span>
                </div>
            )
        }
        return (
            <div style={{
                maxWidth: 1000,
                width: '100%'
            }}>
                {content.map((entry, index) => {
                return (
                    <GraciSupportContent
                    {...entry}
                    key={index} 
                    style={{
                        marginTop: '5vw'
                    }}
                    utils={utils} />
                )
            })}
            </div>
        );
    }

    const getSearchClearButton = () => {
        if(Utils.isMobile() === true) {
            return (
                <img 
                className={'text-button'}
                onClick={onSearchTextChange.bind(this, null)}
                src={'images/search-clear-button-grey.png'}
                style={{
                    borderRadius: 15,
                    height: 30,
                    marginRight: 4,
                    minWidth: 30,
                    width: 30
                }} />
            )
        }
        return (
            <div 
            className={'text-button'}
            onClick={onSearchTextChange.bind(this, null)}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.grey(),
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: 8,
                padding: '4px 16px 4px 16px'
            }}>
                <span style={{
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 700,
                    height: 21
                }}>{'Clear'}</span>
            </div>
        )
    }

    const getSearchComponents = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                paddingTop: '5vw',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img 
                    src={'images/support-icons.png'}
                    style={{
                        height: 80,
                        marginBottom: Utils.isMobile() === true ? 12 : 36,
                        objectFit: 'contain',
                        width: 'auto'
                    }} />
                    <span 
                    className={'mb-4 mb-md-5'}
                    style={{
                        ...Appearance.textStyles.bodyText(),
                        width: '100%'
                    }}>{`The search bar is designed to help you quickly locate support articles and tutorials. By entering keywords or phrases related to your topic, you'll gain access to a library of resources specifically tailored to your needs.`}</span>
                    <TextField
                    appendContent={getSearchButtons()}
                    containerStyle={{
                        borderRadius: 35,
                        paddingBottom: 28,
                        paddingLeft: 28,
                        paddingRight: 16,
                        paddingTop: 28
                    }}
                    onChange={onSearchTextChange}
                    onKeyDown={onSearchFieldKeyDown}
                    placeholder={'What can we help you find?'}
                    useDelay={true}
                    value={searchText} />
                </div>
            </div>
        )
    }

    const getSearchSubmitButton = () => {
        if(Utils.isMobile() === true) {
            return (
                <img 
                className={'text-button'}
                onClick={onManuallyStartSearch}
                src={'images/search-submit-button-primary.png'}
                style={{
                    borderRadius: 15,
                    height: 30,
                    minWidth: 30,
                    width: 30
                }} />
            )
        }
        return (
            <div 
            className={'text-button'}
            onClick={onManuallyStartSearch}
            style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.primary(),
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '4px 16px 4px 16px'
            }}>
                {loading === 'search' && (
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/dots-white.json')}
                    style={{
                        height: 21,
                        width: 25
                    }}/>
                )}
                {loading !== 'search' && (
                    <span style={{
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 700,
                        height: 21
                    }}>{'Search'}</span>
                )}
            </div>
        )
    }

    const getSearchButtons = () => {
        return searchText && searchText.length > 0 && (
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                {getSearchClearButton()}
                {getSearchSubmitButton()}
            </div>
        )
    }

    const fetchResources = async () => {
        try {

            // fetch graci support content from server
            let { content, paging } = await Request.get(utils, '/utils/', {
                limit: limit,
                offset: offset.current,
                search_text: searchTextRef.current,
                type: 'replicated_website_support_resources_content'
            });

            // format entry results
            let entries = content.map(entry => ({
                ...entry,
                date: moment.utc(entry.date).local()
            }));

            // append results to local state if paging has advanced past the first page
            setContent(results => offset.current > 0 ? results.concat(entries) : entries);

            // end loading and update state for paging props
            setLoading(false);
            setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the support resources list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchResources();
    }, []);

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2vw',
            width: '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership}
            identifier={'support'}
            title={'Support'}
            utils={utils} />
            {getSearchComponents()}
            {getResourcesContent()}
            {getPagingComponents()}
        </div>
    )
}

const ResourceText = ({ lastItem = false, style, text }) => {

    const margin = Utils.isMobile() ? 24 : '1vw';

    return (
        <span style={{
            ...Appearance.textStyles.bodyText(),
            display: 'block',
            marginBottom: lastItem === false && margin,
            whiteSpace: 'normal',
            ...style
        }}>{text}</span>
    )
}