import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from 'react-spring';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Draggable from 'react-draggable';
import ProgressBar from 'views/ProgressBar.js';
import ResizeObserver from 'react-resize-observer';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views, { AltBadge } from 'views/Main.js';

export const CalloutIndex = 4950;
export const FloatingMenuIndex = 4550;
export const EndIndex = 4990;
export const FrontIndex = EndIndex + 10;

export const getLayerSizingWidth = sizing => {
    switch(sizing) {
        case 'small':
        return 400;

        case 'medium':
        return 550;

        case 'extra_large':
        return window.innerWidth < 1000 ? window.innerWidth : 1000;

        default:
        return 700;
    }
}

const Layer = ({ buttons, children, header, id, index, options = {}, title, utils }) => {

    const ref = useRef(null);
    const headerRef = useRef(null);
    const buttonContainer = useRef(null);
    const scrollContainer = useRef(null);
    const toolbarContainer = useRef(null);

    const [onHover, setOnHover] = useState(null);
    const [zIndex, setZIndex] = useState(options.zIndex);
    const [layerPosition, setLayerPosition] = useState(options.position);
    const [dragging, setDragging] = useState(false);
    const [height, setHeight] = useState(0);

    const [top, setTop] = useState(window.innerHeight); // for mobile
    const [scale, setScale] = useState(0.75); // for tablet and desktop
    const [opacity, setOpacity] = useState(0); // for tablet and desktop

    const [windowState, setWindowState] = useState({
        action: null,
        frame: null
    });
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const onDragEnded = (evt, node) => {

        // mobile
        if(Utils.isMobile()) {

            // default to page height incase mobile device does not return a Y value
            let y = ref.current ? ref.current.getBoundingClientRect().y : window.innerHeight;
            setTop(y - 36);
            setTimeout(() => {
                setDragging(false);
                if(Utils.isMobile() && y > window.innerHeight / 3) {
                    onWindowActionChange('close');
                } else {
                    setTop(0);
                }
            }, 50);
            return;
        }

        // tablet and desktop
        setDragging(false);
        setLayerPosition({
            y: node.y,
            x: Utils.isMobile() ? 12 : node.x
        });
        if(typeof(options.onReposition) === 'function') {
            options.onReposition({
                id: id,
                position: {
                    x: node.x,
                    y: node.y
                }
            });
        }
    }

    const onDragMoved = () => {
        if(!dragging) {
            setDragging(true);
        }
    }

    const onDragStarted = () => {
        onLayerPress();
    }

    const onLayerAction = ({ detail }) => {
        if(detail.layerID === id) {
            onWindowActionChange(detail.action);
        }
    }

    const onLayerPress = () => {
        setZIndex(FrontIndex);
        options.onSetLayerIndex(id);
    }

    const onMouseEnter = () => {
        document.body.style.overflowY = 'hidden';
    }

    const onMouseLeave = () => {
        document.body.style.overflowY = 'scroll';
    }

    const onUpdateHeight = rect => {
        setHeight(rect.height);
        if(typeof(options.onUpdateHeight) === 'function') {
            options.onUpdateHeight(rect.height)
        }
    }

    const onWindowActionChange = action => {

        if(action === 'close') {
            setOpacity(0);
            setScale(0.75);
            setTop(window.innerHeight);
            document.body.style.overflowY = 'scroll';

            if(typeof(options.onClose) === 'function') {
                setTimeout(options.onClose.bind(this, id), 500);
            }
            return;
        }

        setWindowState(windowState => update(windowState, {
            action: {
                $set: action === windowState.action ? null : action
            }
        }));
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const shouldShowScrollbars = () => {
        return scrollContainer.current ? scrollContainer.current.scrollHeight > getLayerHeight() : false;
    }

    const getFilterOptions = () => {
        if(!options.filter) {
            return null;
        }
        return (
            <div
            className={'not-draggable'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 16
            }}>
                <TextField
                icon={'search'}
                placeholder={options.filter.placeholder || 'Search for something...'}
                onChange={options.filter.onChange} />
            </div>
        )
    }

    const getHeaderContent = () => {
        return (
            <div
            className={dragging ? 'cursor-grabbing' : 'cursor-grab'}
            style={{
                position: 'relative'
            }}>
                <div style={Appearance.styles.header()}>
                    {header}
                    <div
                    ref={headerRef}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div
                        className={'not-draggable'}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center'
                        }}>
                            <AltBadge
                            onClick={onWindowActionChange.bind(this, 'close')}
                            content={{
                                text: 'Close',
                                color: Appearance.colors.red
                            }}
                            style={{
                                width: 60
                            }}/>
                        </div>

                        <span style={{
                            flexGrow: 1,
                            color: Appearance.colors.text(),
                            fontSize: 14,
                            fontWeight: '700',
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{title}</span>

                        <div
                        ref={toolbarContainer}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }} />
                    </div>
                </div>
                {options.loading && (
                    <div style={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        height: '2px'
                    }}>
                        <div style={{
                            overflow: 'hidden',
                            borderRadius: '2px',
                            height: '2px'
                        }}>
                            <ProgressBar/>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const getLayerHeight = () => {
        if(Utils.isMobile() === true) {
            return '100%';
        }
        return size.height - (headerRef.current ? headerRef.current.clientHeight : 0) - (buttonContainer.current ? buttonContainer.current.clientHeight : 0) - 60;
    }

    const getLayerPosition = mobile => {
        if(mobile) {
            return layerPosition || {
                x: -((window.innerWidth / 2) - 12),
                y: 12
            }
        }
        return layerPosition || {
            x: -(getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 0
        }
    }

    const getLayerWidth = () => {
        let width = getWidthForSizing();
        return size.width > width ? width : size.width - 60;
    };

    const getWidthForSizing = () => {
        return getLayerSizingWidth(options.sizing);
    }

    useEffect(() => {
        setZIndex(options.zIndex);
    }, [options]);

    useEffect(() => {
        if(options.position) {
            setLayerPosition({
                y: options.position.y,
                x: Utils.isMobile() ? 12 : options.position.x
            })
        }
    }, [options.position]);

    useEffect(() => {
        switch(options.layerState) {
            case 'close':
            if(opacity !== 0 && scale !== 0.75) {

                setOpacity(0);
                setScale(0.75);
                setTop(window.innerHeight);
                document.body.style.overflowY = 'scroll';

                if(typeof(options.onClose) === 'function') {
                    setTimeout(options.onClose.bind(this, id), 500);
                }
            }
            break;
        }

    }, [options.layerState])

    useEffect(() => {

        if(!ref.current) {
            return
        }
        setTimeout(() => {

            setTop(0);
            setScale(1);
            setOpacity(1);
            setHeight(ref.current.clientHeight);

        }, (250 * ((/*animationDelay ||*/ 0) + 1)))

        window.addEventListener('onLayerAction', onLayerAction);
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('onLayerAction', onLayerAction);
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [ref.current]);

    useEffect(() => {
        if(typeof(options.onMount) === 'function') {
            let { position } = options.onMount({
                options: options,
                getWidthForSizing: getWidthForSizing
            });
            if(position) {
                setLayerPosition(position);
            }
        }
    }, [options.onMount]);

    useEffect(() => {
        if(windowState.action === 'close') {
            return;
        }
        if(typeof(options.onActionChange) === 'function') {
            options.onActionChange({
                action: windowState.action,
                height: getLayerHeight() - headerRef.current.clientHeight
            })
        }
    }, [windowState])

    return Utils.isMobile() ? (

        <Draggable
        key={id}
        axis={'y'}
        cancel={'.not-draggable'}
        onStart={onDragStarted}
        onDrag={onDragMoved}
        onStop={onDragEnded}
        onTouchEnd={onDragEnded}
        position={getLayerPosition(true)}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={dragging ? 0 : 750}
                animation={{
                    top: top
                }}>
                    <div
                    onClick={onLayerPress}
                    style={{
                        position: 'absolute',
                        width: window.innerWidth - 24,
                        height: window.innerHeight - 24,
                        zIndex: zIndex,
                        overflow: 'hidden',
                        boxShadow: '0px 0px 50px rgba(0,0,0,0.2)',
                        border: '3px solid ' + (window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'),
                        borderRadius: 10
                    }}>
                        <div
                        ref={ref}
                        style={{
                            backgroundColor: Appearance.colors.layerBackground(),
                            padding: 0,
                            margin: 0,
                            height: '100%'
                        }}>
                            {getHeaderContent()}
                            {getFilterOptions()}
                            <div
                            className={'not-draggable'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden'
                            }}>
                                {options.sticky ? options.sticky.top : null}
                                <div style={{
                                    flexGrow: 1,
                                    overflowX: 'hidden',
                                    overflowY: 'scroll',
                                    padding: options.removePadding ? 0 : 12,
                                    maxHeight: '100%'
                                }}>
                                    {children}
                                </div>

                                {options.sticky ? options.sticky.bottom : null}
                                {buttons && (
                                    <div
                                    ref={buttonContainer}
                                    style={{
                                        padding: 12,
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        <div className={'row p-0 m-0'}>
                                            {buttons.filter(b => {
                                                return b.visible !== false;
                                            }).map((button, index, buttons) => {

                                                let paddingX = 'px-0';
                                                if(buttons.length === 2) {
                                                    paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                                                } else if(buttons.length > 2) {
                                                    paddingX = index === 1 ? 'px-2' : 'px-0';
                                                }
                                                return (
                                                    <div
                                                    key={index}
                                                    className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                                        <Button
                                                        type={'large'}
                                                        label={button.text}
                                                        color={button.color}
                                                        loading={button.loading}
                                                        onClick={button.onClick}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>

    ) : (

        <Draggable
        key={id}
        onStart={onDragStarted}
        onDrag={onDragMoved}
        onStop={onDragEnded}
        cancel={'.not-draggable'}
        position={getLayerPosition()}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndex
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={500}
                animation={{
                    scale: scale,
                    opacity: opacity
                }}>
                    <div
                    onClick={onLayerPress}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        position: 'fixed',
                        width: getLayerWidth(),
                        height: 'auto',
                        zIndex: zIndex,
                        overflow: 'hidden',
                        boxShadow: '0px 0px 50px rgba(0,0,0,0.2)',
                        border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.layerBackground(),
                    }}>
                        <ResizeObserver onResize={onUpdateHeight}/>
                        <div
                        ref={ref}
                        style={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0
                        }}>
                            <div>
                                {getHeaderContent()}
                                {getFilterOptions()}
                                <div
                                className={'not-draggable'}
                                style={{
                                    position: 'relative'
                                }}>
                                    {options && options.loading && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '0px',
                                            left: '0px',
                                            right: '0px',
                                            height: '2px'
                                        }}>
                                            <div style={{
                                                overflow: 'hidden',
                                                borderRadius: '2px',
                                                height: '2px'
                                            }}>
                                                <ProgressBar/>
                                            </div>
                                        </div>
                                    )}
                                    {options.sticky ? options.sticky.top : null}
                                    <div
                                    ref={scrollContainer}
                                    className={shouldShowScrollbars() ? 'custom-scrollbars' : ''}
                                    style={{
                                        height: '100%',
                                        overflowX: 'hidden',
                                        overflowY: 'scroll',
                                        padding: options.removePadding ? 0 : 12,
                                        maxHeight: getLayerHeight(),
                                    }}>
                                        {children}
                                    </div>
                                    {options.sticky ? options.sticky.bottom : null}
                                    {buttons && (
                                        <div
                                        ref={buttonContainer}
                                        style={{
                                            padding: 12,
                                            borderTop: `1px solid ${Appearance.colors.divider()}`
                                        }}>
                                            <div style={{
                                                maxWidth: 700,
                                                margin: 'auto'
                                            }}>
                                                <div className={'row p-0 m-0'}>
                                                    {buttons.filter(b => {
                                                        return b.visible !== false;
                                                    }).map((button, index, buttons) => {
                                                        let paddingX = 'px-0';
                                                        if(buttons.length === 2) {
                                                            paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                                                        } else if(buttons.length > 2) {
                                                            paddingX = index === 1 ? 'px-2' : 'px-0';
                                                        }
                                                        return (
                                                            <div
                                                            key={index}
                                                            className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                                                <Button
                                                                type={'large'}
                                                                label={button.text}
                                                                color={button.color}
                                                                loading={button.loading}
                                                                onClick={button.onClick}/>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>
    )
}

export const LayerItem = ({ badge, children, childrenStyle, collapsed, headerStyle, headerTitleStyle, lastItem, leftContent, onVisibilityChange, spacing = 36, subTitle, title, required, rightContent, style }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: collapsed ? 0 : 1,
        maxHeight: collapsed ? 0 : 1500,
        config: { mass: 1, tension: 180, friction: 30 }
    }));

    const onCollapseClick = () => {
        let next = !_collapsed;
        _setCollapsed(next);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange(next);
        }
    }

    const isCollapseEnabled = () => {
        return typeof(_collapsed) === 'boolean' ? true : false;
    }

    const getAnimationsStyles = () => {
        if(isCollapseEnabled()) {
            return {
                overflowX: 'hidden',
                overflowY: 'scroll',
                ...animations
            };
        }
        return null;
    }

    const getCollapseStyles = () => {
        if(!isCollapseEnabled() || _collapsed === false) {
            return {
                marginBottom: lastItem ? 0 : spacing
            };
        }
        return {
            paddingBottom: 2,
            marginBottom: _collapsed ? 24 : spacing,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }

    useEffect(() => {
        setAnimations({
            opacity: _collapsed ? 0 : 1,
            maxHeight: _collapsed ? 0 : 1500
        });
    }, [_collapsed]);

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    return (
        <div style={{
            width: '100%',
            ...getCollapseStyles(),
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'left',
                width: '100%'
            }}>
                {required && (
                    <div style={{
                        backgroundColor: Appearance.colors.red,
                        borderRadius: 4,
                        height: 8,
                        marginRight: 8,
                        minHeight: 8,
                        minWidth: 8,
                        overflow: 'hidden',
                        width: 8
                    }} />
                )}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    jusitfyContent: 'space-between',
                    marginBottom: 8,
                    minWidth: 0,
                    ...headerStyle
                }}>
                    {leftContent}
                    {typeof(title) === 'string' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.layerItemTitle(),
                                whiteSpace: 'normal',
                                ...headerTitleStyle
                            }}>{title}</span>
                            {typeof(subTitle) === 'string' && (
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    display: 'block'
                                }}>{subTitle}</span>
                            )}
                        </div>
                    )}
                    {badge && (
                        <AltBadge
                        content={badge}
                        style={{
                            marginLeft: 8,
                            marginRight: 0,
                            top: 0
                        }} />
                    )}
                </div>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {rightContent}
                    {isCollapseEnabled() && (
                        <CollapseArrow
                        collapsed={_collapsed}
                        onClick={onCollapseClick} />
                    )}
                </div>
            </div>
            <animated.div style={{
                width: '100%',
                ...childrenStyle,
                ...getAnimationsStyles()
            }}>
                {children}
            </animated.div>
        </div>
    )
}

export const CollapseArrow = ({ collapsed, color, onClick, style }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        transform: `rotate(${collapsed ? '0deg' : '180deg'})`
    }));

    const onCollapseClick = evt => {
        let nextCollapse = !_collapsed;
        if(typeof(onClick) === 'function') {
            onClick(nextCollapse, evt);
        }
        _setCollapsed(nextCollapse);
    }

    const getImage = () => {
        switch(color) {
            case Appearance.colors.primary():
            return `images/down-arrow-blue-small.png`;

            default:
            return 'images/down-arrow-grey-small.png';
        }
    }

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    useEffect(() => {
        setAnimations({ transform: `rotate(${_collapsed ? '0deg' : '180deg'})` });
    }, [_collapsed]);

    return (
        <div syle={{
            height: 15,
            width: 15,
            ...style
        }}>
            <animated.img
            className={'text-button'}
            onClick={onCollapseClick}
            src={getImage()}
            style={{
                width: 15,
                height: 15,
                objectFit: 'contain',
                ...style,
                ...animations
            }} />
        </div>
    )
}

export default Layer;
