import React from 'react';
import Appearance from 'styles/Appearance.js';
import { NavigationSectionHeader } from 'managers/Components.js';
import RichTextEditor from 'views/RichTextEditor';

export const PrivacyPolicy = ({ dealership, omnishield, utils }) => {

    const getAppName = () => {
        if(!omnishield) {
            return '...';
        }
        if(omnishield.enabled === false) {
            return 'OmniShield App';
        }
        if(omnishield.android.app_name === omnishield.ios.app_name) {
            return `${omnishield.ios.app_name} App`;
        }
        return `${omnishield.ios.app_name} App for iOS and ${omnishield.android.app_name} App for Android`;
    }

    const getCustomPolicyContent = () => {

        let { blocks = [] } = dealership.privacy_policy || {};
        if(blocks?.length > 0 && blocks[0]?.text.length > 0) {
            return (
                <RichTextEditor 
                content={dealership.privacy_policy}
                utils={utils} 
                viewer={true} />
            )
        }
    }

    const getEmailAddress = () => {
        if(!omnishield) {
            return '...';
        }
        return omnishield.enabled === true ? dealership.email_address : 'info@homesafenetwork.com';
    }

    const getOmniShieldPrivacyPolicy = () => {

        let sections = [{
            title: `${getAppName()} Privacy Statement.`,
            values: [
                `This Privacy Statement for the ${getAppName()} (the “App”) is provided by Global Health & Safety LLC (“Global”), manufacturer of the products to which the App pertains. Those products include combination smoke and heat-activated alarms, standalone heat alarms, carbon monoxide alarms, water sensors, and bed shakers (the “Products”), all of which communicate with one another via radio frequency. Some or all of these Products have been installed in your home by an authorized OmniShield dealer.`
            ]
        },{
            title: 'Personal Information Collected by the App.',
            values: [
                `The App collects the following personal information from you: your name; the residential location at which the Products purchased by you are installed; your cell phone number; and your email address. The App also collects the following personal information as to up to seven other persons who have consented to your including such personal information in the App (“Your Contacts”): such persons' names, cell phone numbers, and in some instances email addresses.`
            ]
        },{
            title: 'Use Made of Personal Information.',
            values: [
                `All of the personal information collected by the App is solely used for purposes directly associated with the App. The "Home Address" page of the App lists your residential address, at which the Products are installed. That item of personal information is not shared with any person or company.`,
                `The "Contacts" page of the App contains your name, your cell phone number, and (if you have provided it) your email address. The Contacts page also contains the names, cell phone numbers, and potentially the email addresses of Your Contacts.`,
                `We do not sell such names, cell phone numbers, and email addresses to anyone. We share such personal information only with businesses with which we contract (“Third Parties”) in order to provide you the services made available by the App. Those Third Parties are responsible, when one or more Alarms in your home detects a condition of concern, for causing text messages and potentially email messages to be sent to you and to Your Contacts. That is the only reason we share personal information with Third Parties. If we did not share personal information with such Third Parties, the App would not work, and neither you nor Your Contacts would receive text alerts or email alerts as to a condition of concern which has arisen at your home.`
            ]
        },{
            title: 'Changing Personal Information.',
            values: [
                `If the cell phone number or email address of you or any of Your Contacts changes, you need only enter that change in the App. Alerts will thereafter be sent to the changed cell phone number and/or email address.`
            ]
        },{
            title: 'Your Control Over Personal Information.',
            values: [
                `You entirely control the personal information we possess and that we share with Third Parties. If you no longer wish us to have your personal information, all you need to do is inform us by email sent to ${getEmailAddress()}. We will delete your personal information, as well as the personal information of Your Contacts, and notify the Third Parties that they too are to delete such personal information.`,
                `If one of Your Contacts no longer wishes us to have his or her personal information, all such person need do is so inform us by email sent to ${getEmailAddress()}, identifying you in the email as the OmniShield product owner for whom he or she has been listed as a Contact. We will delete such person's personal information and notify the Third Parties that they are also to do so.`
            ]
        },{
            title: 'Notification to Your Contacts.',
            values: [
                `You agree to inform all of Your Contacts of their need to download the App and view this Privacy Statement, to make sure they understand the provisions herein applicable to them.`
            ]
        },{
            title: 'Security.',
            values: [
                `We take precautions to protect and securely maintain all of the personal information collected by the App. Access to such personal information at Global is severely restricted and available only to the person or persons whose work responsibilities include overseeing the App. All transmissions by us to Third Parties, containing personal information, are encrypted to ensure that such personal information is protected.`
            ]
        },{
            title: 'Questions.',
            values: [
                `If you have any questions concerning this privacy policy or the App, please direct them in the first instance to info@homesafenetwork.com.`
            ]
        }]

        return sections.map((section, index) => {
            return (
                <div 
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: index !== sections.length - 1 ? 36 : 0,
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1.2,
                        marginBottom: 12
                    }}>{section.title}</span>
                    {section.values.map((value, index, values) => {
                        return (
                            <span 
                            key={index}
                            style={{
                                color: Appearance.colors.text(),
                                display: 'block',
                                fontWeight: 400,
                                fontSize: 16,
                                lineHeight: '24px',
                                marginBottom: index !== values.length - 1 ? 8 : 0
                            }}>{value}</span>
                        )
                    })}
                </div>
            )
        });
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width:  '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership} 
            identifier={'privacy'}
            title={'Privacy Policy'} 
            utils={utils} />
            <div style={{
                padding: '5vw',
                maxWidth: 1250,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 36,
                    padding: 36,
                    width: '100%'
                }}>
                    {getOmniShieldPrivacyPolicy()}
                </div>
                {getCustomPolicyContent()}
            </div>
        </div>
    )
}

export const TermsAndConditions = ({ dealership, omnishield, utils }) => {

    const getAppName = () => {
        if(!omnishield) {
            return '...';
        }
        if(omnishield.enabled === false) {
            return 'OmniShield App';
        }
        if(omnishield.android.app_name === omnishield.ios.app_name) {
            return `${omnishield.ios.app_name} App`;
        }
        return `${omnishield.ios.app_name} App for iOS and ${omnishield.android.app_name} App for Android`;
    }

    const getContent = () => {

        let sections = [{
            title: `${getAppName()} Terms and Conditions.`,
            values: [
                `Thank you for acquiring the Comm Link communications device (the “Product”) made by Applied Fire Technologies LLC (the “Company”), along with its accompanying software (the “App”).`,
                `This document describes the terms of terms of our agreement relating to your use of the Product and related services (the “Agreement”). It is a legally binding contract. Please read it carefully. If there is any provision in the Agreement with which you do not agree, please do not register your device and please do not use the Product.`,
                `We have tried to use plain language that laypersons will understand. We want our Agreement to be clear and understandable to both of us. In some instances, our lawyers insisted that we use denser language and even required us to put several paragraphs in capitalized print. But whenever possible, we have tried to use simple prose.`,
                `We wish briefly to examine one important provision of this Agreement right at the outset, and that is the arbitration clause. We are agreeing that, if we have a dispute that we are unable to resolve between ourselves, we will ask an arbitrator to resolve that dispute for us. Arbitration is quicker and less expensive than litigation. However, the agreement to arbitrate a dispute does mean that the parties have waived their right to jury trial. If you are not willing to waive your right to jury trial in the event we are unable to resolve disputes which may arise between us, please do not register your device and do not use the Product.`
            ]
        },{
            title: 'What The Product Does.',
            values: [
                `The Product is a communications device. It collects information from alarms in your home, and communicates that information to an off-site location sometimes referred to as being in the “Cloud.” At that location, the information is analyzed. If that analysis reveals an alarm activation or other condition of serious concern at your home, a message is communicated to you by text and email.`,
                `In order for the Product to work, you must download the App into your phone or tablet from the Apple App Store (for Apple products) or the Google Play Store (for Android-based products). Once you have downloaded the App into your phone or tablet, the Product is ready to be set up and used.`,
                `You have in your home a number of other products made by the Company, such as smoke alarms, heat alarms, carbon monoxide alarms, water sensors, and bed-shakers (together, the “Alarms;” individually, an “Alarm”). The Alarms communicate with one another by way of a radiofrequency. Using the same radiofrequency, the Product acquires information from the Alarms. After converting that information into the appropriate form, the Product transmits the information over the Internet to an item of computer hardware in the Cloud known as a server. The server analyzes the information. If that analysis indicates that an Alarm activation at your home has occurred, or some other condition of significant concern exists, the server software causes a text message to be sent to your cellphone and an email message to be sent to your email address (if an email address has been entered).`,
                `You can designate up to seven persons, in addition to yourself, to receive the text and email notifications. Those persons might include your immediate neighbors.`,
                `The Product and App can provide additional information, as to the statuses of Alarms in your network, to a smartphone or tablet into which you have downloaded the App. The Owner's Manual for the Product examines this information and how you can access it.`,
                `The various services provided to you by the Product and App, as described in this Agreement and the Owner's Manual for the Product, are referred to herein as the “Services.”`
            ]
        },{
            title: 'What The Product Does Not Do.',
            values: [
                `The Product and App do not promise that anything in particular will be done with the information that is communicated to you.`,
                `The Product and App do not promise that you will receive the information that has been texted to you. You will not receive that information if (for example) your cell phone is turned off; the battery in your cell phone has died; you do not have your cell phone with you or do not check texts received by your phone; your cell phone is located in a spot where your cell phone carrier is unable to provide service (i.e., you are in a “dead zone”); an electrical storm or other weather event has disrupted cell phone service; some other interruption in cell phone signal transmission and service has occurred; you mistakenly delete the text message before reading it; the Internet service to your home has been interrupted; or anything else results in your not actually receiving the information texted to your cell phone.`,
                `The Product and App do not promise that you will receive the information that has been emailed to you. You will not receive that information if (for example) you do not have with you the device on which you receive and read emails; the power to such device has been disconnected or has failed; an electrical storm or other weather event has disrupted Internet service; the Internet connection to your device, provided by your Internet Service Provider, has been disconnected, lost, or has failed; you mistakenly delete the email message before reading it; you do not read emails you have received; or anything else occurs that results in your not actually receiving the information emailed to you.`,
                `The Product and App do not promise that persons to whom notifications are sent, in addition to you, will actually receive such notifications or will take any action at all in response to having received them.`,
                `The Product and App will not result in any persons being sent to your home in response to an alarm activation or detection of a condition of concern at your home. They will not result in the placement of a call to a fire department, police department, or any other governmental body. They do not constitute an alarm monitoring system. The only notifications provided by the Product and App are notifications to you (and to up to seven additional persons identified by you). What to do upon receiving a notification - if anything - is up to you.`,
                `The Product and App do not constitute any form of insurance. They do not serve in any way to compensate you for any property damage, personal injury, and loss you actually suffer should there be a fire, flood, carbon monoxide poisoning, or other calamitous event, at your home. You should insure yourself against such damage, injury, and loss.`,
                `The Product and App do not constitute any form of emergency notification or response device. The Services will not affect or increase your level of safety. They do not constitute any form of lifesaving solution for people at risk in the home, and they are not a substitute for emergency services. Any and all life threatening and emergency events should be directed to the appropriate response services.`,
                `The Product and App are not designed or intended to work, and will not work, with alarms other than OmniShield Alarms made by the Company.`,
                `The Product and App may not work outside of the United States and Canada; the Company does not promise that they will work outside of those two countries.`,
                `Please do not expect the Product and App to do more than they actually do.`
            ]
        },{
            title: 'Your Responsibilities.',
            values: [
                `You must download the App from one of the available locations and use it properly.`,
                `You must maintain a working Ethernet network in your home to which the Product is connected, or alternatively a working Wi-Fi network in your home that is positioned to reliably communicate with the Product. You must also provide electrical power to the Product and to all items the functioning of which is necessary in order for you to have broadband Internet service, such as modems and routers.`,
                `You are responsible for establishing and maintaining some form of backup power, so that in the event of a power outage, you will still have a functioning network, and you will still have uninterrupted Internet access without which the Product and App will not work.`,
                `You must ensure that the cell phone number and email address that you have provided to the Company are accurate and remain accurate. It is your responsibility to notify the Company of any change in your cell phone number and/or email address.`,
                `You are responsible for ensuring that all of the Alarms in your home have been correctly installed and maintained in accordance with the owners' manuals for such products.`,
                `You are responsible for testing all of the Alarms in your home, every month if not more frequently, to ensure that they are operating properly; and if any Alarm is not operating properly, you are responsible for taking the steps necessary to restore the Alarm to full working order.`,
                `You are responsible for reading the Owner's Manual for the Product and following the instructions and recommendations stated in that Manual as to the installation, testing, use, and maintenance of the Product and App.`,
                `You must obtain and maintain homeowner's liability insurance which will compensate you for any property damage, personal/bodily injury, or other loss which occurs at your home, including but not limited to damage to (and loss of use of) the home and other improvements constructed on your property, and damage to (and loss of use of) the personal property and fixtures found in your home or on the premises. The Product and App are not intended to, and in fact will not, prevent such damage, injury or loss from occurring, or mitigate any such damage, injury or loss which does occur.`
            ]
        },{
            title: 'Third Party Products and Services.',
            values: [
                `To provide the App and the Services, we necessarily rely on equipment and services provided by many persons and companies who are not parties to this Agreement. The equipment, none of which is owned by us, includes (but is not limited to) servers, switches, connectors, cables, transformers, routers, modems, hard drives, portable storage media, keyboards, batteries, phones, power sources, electricity transmission equipment, and other items (together, the “Equipment”), some of which are owned by you. The persons and companies on whose services we necessarily rely include (but are not limited to) your cell phone service provider; your broadband Internet service provider; the operators of the Apple App Store and Google Play Store Internet sites from which the App can be downloaded; the operators of Equipment through and over which information is conveyed from the Alarms to the Site and from the Site to you; the persons who service and maintain such Equipment; and the persons who operate and maintain Equipment owned by you, including your cell phone, tablet, modem, router, cables, and connectors.`,
                `And, of course, we rely - as do you - on a properly functioning Internet.`,
                `It is important for you to realize that our ability to provide the Services depends on the proper functioning and maintenance of so many items of Equipment, and the proper action of so many persons and companies, over which we simply have no control.`,
                `An example will illustrate our necessary reliance on Equipment and services provided by others. Suppose the App attempts to send a text message to you of a condition of concern at your home. You may not receive that text message if your cell phone is physically located in a “dead” spot where your cell phone provider is simply unable to supply service; if cell phone service in your area is disrupted by an electrical storm; if a necessary server or switch malfunctions, perhaps due to inadequate maintenance, interrupting the text message that would otherwise have been sent to and received by you; or if you have failed to ensure that the battery in your cell phone is live and functioning.`,
                `Of necessity, we are not responsible for errors, failures, malfunctions, outages, and other events over which we have no control.`
            ]
        },{
            title: 'Assignment.',
            values: [
                `The Company may assign this Agreement, and all of its duties and obligations thereunder, to any person or entity who promises to perform all of those duties and obligations, with or without your consent. The Company may subcontract or delegate its performance of any of its duties and obligations hereunder to any person or entity who promises to perform the subcontracted duties and obligations, again with or without your consent.`,
                `You may not assign this Agreement, or any of your duties and obligations and rights hereunder, to any person or entity, either voluntarily or by operation of law. For example, you may not assign any rights under this Agreement to a tenant who has rented the home in which the Alarms are installed for which the Product and App provide notifications. Nor may this Agreement, in whole or in part, be “subrogated” as a matter of law to any person or entity. This Agreement and its benefits are personal to you, and the obligations it imposes are solely yours.`
            ]
        },{
            title: 'DISCLAIMER OF WARRANTIES.',
            values: [
                `THE COMPANY DOES NOT PROVIDE ANY WARRANTY, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE COMM LINK PRODUCT, THE APP, OR ANY OF THE SERVICES DESCRIBED HEREIN. THE IMPLIED WARRANTIES HERE DISCLAIMED PARTICULARLY INCLUDE, BUT ARE NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, AND SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.`,
                `YOUR USE OF THE COMM LINK, APP AND SERVICES IS CONDITIONED UPON YOUR AGREEMENT THAT THE COMPANY HAS NOT PROVIDED ANY EXPRESS WARRANTY, AND TO THE EXTENT PERMITTED BY LAW HAS NOT PROVIDED ANY IMPLIED WARRANTY, WITH RESPECT TO THE PRODUCT OR APP OR ANY OF THE SERVICES DESCRIBED HEREIN. IF YOU DO NOT AGREE TO SUCH DISCLAIMER OF WARRANTIES, YOU SHOULD NOT USE THE COMM LINK OR DOWNLOAD THE APP.`
            ]
        },{
            title: 'LIMITATION OF DAMAGES.',
            values: [
                `TO THE EXTENT PERMITTED BY LAW, THE COMPANY IS NOT LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES IN ANY WAY ARISING OUT OF (1) THE DESIGN OR PERFORMANCE OF THE COMM LINK OR THE RENDERING OF SERVICES DESCRIBED HEREIN, OR (2) ANY ACTUAL OR ALLEGED NON-PERFORMANCE OF THE COMM LINK OR FAILURE TO RENDER ANY OF THE SERVICES DESCRIBED HEREIN, REGARDLESS OF THE REASONS FOR SUCH NON-PERFORMANCE OR FAILURE, AND EVEN IF THE COMPANY KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES. THE DAMAGES HERE EXCLUDED PARTICULARLY INCLUDE, BUT ARE NOT LIMITED TO, ANY AND ALL (A) PERSONAL/BODILY INJURY, (B) DAMAGES TO AND LOSS OF THE PRODUCT OWNER’S HOME AND OTHER IMPROVEMENTS CONSTRUCTED ON THE PRODUCT OWNER'S PROPERTY, AND (C) DAMAGES TO AND LOSS OF THE CONTENTS OF AND FIXTURES TO SUCH HOME AND IMPROVEMENTS. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.`,
                `YOUR USE OF THE COMM LINK, APP AND SERVICES IS CONDITIONED UPON YOUR AGREEMENT TO THE FOREGOING LIMITATION OF DAMAGES. IF YOU DO NOT AGREE TO THAT LIMITATION, YOU SHOULD NOT USE THE COMM LINK OR DOWNLOAD THE APP.`,
                `We are also not responsible for any damages resulting from events over which we have no control, including (but not limited to) floods; power outages; high winds; electrical storms; acts of God; war; hostilities; terrorism; the failure of equipment and communications controlled by third parties such as your cell phone service provider and Internet Service Provider; product abuse, misuse or alteration; theft; product non-maintenance; and unauthorized product repair.`
            ]
        },{
            title: 'ARBITRATION; WAIVER OF JURY TRIAL.',
            values: [
                `Any claim, dispute, or demand by either of us, allegedly arising from or in any way relating to this Agreement, the Product, or any of the Services, shall be resolved by binding arbitration before an arbitral entity located in the State in which you reside and selected by the party initiating the arbitration, except that the American Arbitration Association may not be selected as the arbitral entity. All questions as to whether or not an issue constitutes a matter arbitrable hereunder shall themselves be settled by arbitration. The arbitrator may require each party to produce relevant documents and may permit each side to take up to three depositions, each of which may not exceed one day. No other discovery may be taken. Any arbitration award shall be final, finding, and conclusive upon the parties and their privies. The parties knowingly waive any right to trial by jury of any issue in any such arbitration between them. In any such arbitration, neither party may recover punitive damages more than three (3) times the amount of proven compensatory damages, no matter how small or large such compensatory damages may be. The prevailing party shall recover all costs of the arbitration, including such party's share of the arbitrator's fees but not including attorneys' fees. Should you be unable to pay any fee needed to institute such arbitration or your share of an interim bill for the arbitrator's services, the Company will advance the monies to make such payments, accepting the risk that it will not be able to recoup such monies should the arbitrator rule in its favor.`,
                `No Class Arbitration. The arbitrator may award declaratory and/or injunctive relief only in favor of the individual party seeking relief (either you or the Company) and only to the extent necessary to provide relief warranted by that party's individual claims. We both agree that each of us may only bring claims against the other in our individual capacities, and not as a plaintiff or class member in any purported class, representative, or private attorney general proceeding. In addition, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of class or representative proceeding.`,
                `YOUR USE OF THE COMM LINK, APP AND SERVICES IS CONDITIONED UPON YOUR AGREEMENT TO THE FOREGOING ARBITRATION PROVISION (AND THE WAIVER OF JURY TRIAL EMBODIED THEREIN). IF YOU DO NOT AGREE TO THAT PROVISION, YOU SHOULD NOT USE THE COMM LINK OR DOWNLOAD THE APP.`
            ]
        },{
            title: 'Miscellaneous and Legal.',
            values: [
                `1. Complete Agreement. This Agreement constitutes the sole contract between us with respect to the matters set forth herein. It supersedes any and all previous discussions, negotiations, understandings, and agreements between us, whether oral or written or both. The contract between us is entirely written; it does not include any oral promises, representations, terms, or statements. To be effective, a modification of this Agreement must be written; no oral modifications or amendments are permitted.`,
                `2. Changes to This Agreement. The Company reserves the right to make written changes to this Agreement, with such changes taking effect immediately. The Company will establish a location on the World Wide Web (the “Website”) at which the current version of the Agreement, as amended from to time, will be posted, can be read, and can be printed. The Company may also notify you of changes in the Agreement by email, snail mail, or both.`,
                `3. Governing Law. This Agreement is governed by the law of the State in which you reside, excluding conflict of laws principles.`,
                `4. Termination. This Agreement may be terminated at any time by either party by the provision of at least 30-days' notice.`,
                `5. Automatic Software Updates. The Company may from time to time develop patches, bug fixes, updates, upgrades and other modifications to the App (“Updates”). You hereby consent to the automatic installation and implementation of such Updates. The Company is not required to provide notice of any Updates to you.`,
                `6. Privacy. The Company will post its privacy policy on the Website, and possibly a related privacy statement for the Product and the Services. Such documents may describe practices regarding the information the Company receives from and may otherwise collect from users of the Product and Services, including you.`,
                `7. Consent to Receipt of Texts and Emails. You consent to our sending to you, and your receipt of, text messages and emails.   From time to time the Company may communicate to you by email information concerning the Product and App, upgrades and improvements to the Product and App, and new products and services that have become available to you. If you do not wish to receive such informational emails you need only communicate that preference in writing to the Company.`,
                `8. Security of Information. The Company will take reasonable measures to maintain the integrity and security of personal information you provide. However, the Company cannot guarantee that unauthorized third persons will never be able to defeat our security measures or use your personal information for improper purposes. Necessarily you are providing your personal information at your own risk.`,
                `9. No Waiver of Performance. The failure of either of us at any time to require full performance by the other of any provision of this Agreement shall not affect such party's right to require such performance at any time thereafter; nor shall the waiver by either party of a breach of any provision herein constitute a waiver of any subsequent breach of the same or any other provision of this Agreement.`,
                `10. Severability. Should any provision of this Agreement be found to be unlawful, such provision shall be deemed to have been severed from this Agreement, and every other provision of this Agreement shall remain in full force and effect.`,
                `11. Notices. Any notice required of you by this Agreement must be sent by first class mail, postage prepaid, to the Company at the following address: Applied Fire Technologies LLC, 825 W/ Sandy Lake Road, Suite 190, Coppell TX 75019. We may provide notice to you by email, text message, or regular mail.`,
                `12. Survival. All provisions of this Agreement that would naturally survive its termination – for example, the Disclaimer of Warranties, Limitation of Damages, and Arbitration provisions - in fact do so.`,
                `13. Gender. References herein to “he” and “him” shall be deemed to be references to “she” and “her” where the referenced person is female.`
            ]
        }]

        return sections.map((section, index) => {
            return (
                <div 
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: index !== sections.length - 1 ? 36 : 0,
                    width: '100%'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1.2,
                        marginBottom: 12
                    }}>{section.title}</span>
                    {section.values.map((value, index, values) => {
                        return (
                            <span 
                            key={index}
                            style={{
                                color: Appearance.colors.text(),
                                display: 'block',
                                fontWeight: 400,
                                fontSize: 16,
                                lineHeight: '24px',
                                marginBottom: index !== values.length - 1 ? 8 : 0
                            }}>{value}</span>
                        )
                    })}
                </div>
            )
        });
    }

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width:  '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership} 
            identifier={'terms_and_conditions'}
            title={'Terms and Conditions'} 
            utils={utils} />
            <div style={{
                padding: '5vw',
                maxWidth: 1250,
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 36,
                    padding: 36,
                    width: '100%'
                }}>
                    {getContent()}
                </div>
            </div>
        </div>
    )
}