import React, { useEffect, useRef, useState } from 'react';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { LayerItem } from 'structure/Layer.js';
import { NavigationSectionHeader } from 'managers/Components.js';
import QueryString from 'query-string';
import Utils from 'files/Utils.js';

export const AboutUs = ({ dealership, utils }) => {

    const getItemContainer = (key, orientation) => {

        let dealership = utils.dealership.get();
        if(!dealership) {
            return null;
        }

        // prepare list of container items
        let items = {
            guiding_values: {
                description: `We're committed to our mission of providing unlimited opportunity for growth to individuals, as we all work together to make the home YOU live in a more fire safe place. We are guided by respect for the individual, a passion for excellence, an obsession with customer service. We believe that when each of us uses of God-given talents, following our company's guiding values, we all win. Our most valuable treasure is the people who represent our company and we treat them that way.`,
                image: 'images/mission-guiding-values.jpg',
                title: 'Guiding Values'
            },
            about_us: {
                description: dealership.bio || `Our mission is to educate families about the devastating effects of home fires and other home safety issues. Our seminars and one-on-one counseling teach families how to escape and survive the ravages of home fires, carbon monoxide poisoning and in some cases, even prevent property damage. Today's homes present numerous safety challenges. Unfortunately, most home owners are unaware of the hidden dangers they face. We strive to treat each family's safety as our own. Education and advanced detection equipment are the solutions when seconds count.`,
                image: dealership.logo && dealership.logo.url || 'images/dealership-replicated-logo-placeholder.png',
                title: `About ${dealership.name}`
            }
        }

        // prepare content child components
        let content = (
            <div className={`col-12 col-lg-7 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-2' : 'pl-lg-2 pr-lg-4'} py-lg-2`}>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                    marginBottom: 8
                }}>{items[key].title.toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 18,
                    marginBottom: 24
                }}>{items[key].description}</span>
            </div>
        )

        // prepare image child components
        let image = (
            <div className={`col-12 col-lg-5 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-4' : 'pl-lg-4 pr-lg-2'} py-lg-2`}>
                <img 
                src={items[key].image}
                style={{
                    borderRadius: 8,
                    height: 'auto',
                    objectFit: 'cover',
                    width: '100%'
                }} />
            </div>
        )

        // prepare a specialty image component for about_us
        if(key === 'about_us') {
            image = (
                <div className={`col-12 col-lg-5 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-4' : 'pl-lg-4 pr-lg-2'} py-lg-2`}>
                    <div style={{
                        backgroundColor: Appearance.colors.divider(),
                        border: `1px solid ${Appearance.colors.divider()}`,
                        borderRadius: 8,
                        padding: Utils.isMobile() ? 48 : '5vw'
                    }}>
                        <img 
                        src={items[key].image}
                        style={{
                            borderRadius: 8,
                            height: 'auto',
                            objectFit: 'cover',
                            width: '100%'
                        }} />
                    </div>
                </div>
            )
        }

        // determine if a right orientation layout was requsted
        if(orientation === 'right' && Utils.isMobile() === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    width: '100%'
                }}>
                    <div 
                    className={'row m-0'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        maxWidth: 1250,
                        padding: '3vw',
                        width: '100%'
                    }}>
                        {content}
                        {image}
                    </div>
                </div>
            )
        }

        // fallback to returning a left orientation layout
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24,
                width: '100%'
            }}>
                <div 
                className={'row m-0'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    width: '100%'
                }}>
                    {image}
                    {content}
                </div>
            </div>
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership}
            identifier={'about'}
            image={'omnishield-sensors-group.jpg'}
            title={'About Our Company'}
            utils={utils} />
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5vw',
                width: '100%'
            }}>
                {getItemContainer('guiding_values', 'left')}
                {getItemContainer('about_us', 'right')}
            </div>
        </div>
    )
}

export const ContactSurvey = ({ dealership, utils }) => {

    const [height, setHeight] = useState(700);

    const onHeightChange = evt => {
        if(evt.data.height) {
            setHeight(evt.data.height);
        }
    }
    
    useEffect(() => {

        // add event listener for iframe contents height change
        window.addEventListener('message', onHeightChange);
        return () => {
            window.removeEventListener('message', onHeightChange);
        }

    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership}
            identifier={'contact'}
            image={'omnishield-sensors-group.jpg'}
            title={'Contact Us'}
            utils={utils} />
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                paddingTop: '5vw',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    textAlign: 'center'
                }}>
                    <img 
                    src={'images/vector-contact-icons.png'}
                    style={{
                        height: 90,
                        marginBottom: Utils.isMobile() === true ? 12 : 36,
                        objectFit: 'contain',
                        width: 'auto'
                    }} />
                    <span style={{
                        color: Appearance.colors.text(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(400),
                        fontSize: 18
                    }}>{dealership.contact_form_text || 'Thank you for taking the time to reach out. Please include your questions below along with you full name, email, and phone number so we can contact you. We typically respond to most inquiries within 24-48 hours.'}</span>
                </div>
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '5vw',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                width: '100%'
            }}>
                <iframe 
                src={`${API.server}/support/?id=14&dealership_id=${dealership.id}&dealership_access_token=${dealership.access_token}`}
                style={{
                    border: 'none',
                    height: height,
                    width: '100%'
                }} />
            </div>
        </div>
    )
}

export const Education = ({ dealership, utils }) => {

    const getItemContainer = (key, orientation) => {

        // prepare list of container items
        let items = {
            company: {
                description: `Whether through our in-home appointments or group seminars, our team educates families on prevention & preparation. We teach families to select a meeting spot and ensure everyone knows where to go. We help families see the value in planning their escape routes and the importance of practicing their plan. `,
                image: 'images/company_education.jpg',
                title: 'Our company offers education.'
            },
            families: {
                description: `We show family members how to use extinguishers, fire-blankets, and identify hazards before they become a problem. We evaluate escape routes for obstructions and teach the value of closed doors to slow the spread of fire. We inform families of detection technologies and advise proper placement and installation. We teach families about the misconceptions of home fire and carbon monoxide poisoning and we offer solutions. When we are finished, families are better prepared for a disaster that could happen when they least expect it.`,
                image: 'images/preparing_families.jpg',
                title: 'Preparing Families'
            }
        }

        // prepare content child components
        let content = (
            <div className={`col-12 col-lg-7 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-2' : 'pl-lg-2 pr-lg-4'} py-lg-2`}>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                    marginBottom: 8
                }}>{items[key].title.toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 18,
                    marginBottom: 24
                }}>{items[key].description}</span>
            </div>
        )

        // prepare image child components
        let image = (
            <div className={`col-12 col-lg-5 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-4' : 'pl-lg-4 pr-lg-2'} py-lg-2`}>
                <img 
                src={items[key].image}
                style={{
                    borderRadius: 8,
                    height: 'auto',
                    objectFit: 'cover',
                    width: '100%'
                }} />
            </div>
        )

        // determine if a right orientation layout was requsted
        if(orientation === 'right' && Utils.isMobile() === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    width: '100%'
                }}>
                    <div 
                    className={'row m-0'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        maxWidth: 1250,
                        padding: '3vw',
                        width: '100%'
                    }}>
                        {content}
                        {image}
                    </div>
                </div>
            )
        }

        // fallback to returning a left orientation layout
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24,
                width: '100%'
            }}>
                <div 
                className={'row m-0'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    width: '100%'
                }}>
                    {image}
                    {content}
                </div>
            </div>
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <NavigationSectionHeader
            dealership={dealership}
            identifier={'education'}
            title={'Education for Families'}
            utils={utils} />
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5vw',
                width: '100%'
            }}>
                {getItemContainer('company', 'left')}
                {getItemContainer('families', 'right')}
            </div>
        </div>
    )
}


export const MobileAppSupport = ({ dealership }) => {

    const [height, setHeight] = useState(700);
    const [platform, setPlatform] = useState(null);

    const onHeightChange = evt => {
        if(evt.data.height) {
            setHeight(evt.data.height);
        }
    }

    useEffect(() => {

        // update local state with requested platform
        let { platform } = QueryString.parse(window.location.search);
        setPlatform(platform);

        // add event listener for iframe contents height change
        window.addEventListener('message', onHeightChange);
        return () => {
            window.removeEventListener('message', onHeightChange);
        }

    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 0,
                paddingLeft: '5vw',
                paddingRight: '5vw',
                paddingTop: '5vw',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    textAlign: 'center'
                }}>
                    <div style={{
                        alignItems: 'center',
                        //backgroundColor: Appearance.colors.divider(),
                        borderRadius: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginBottom: Utils.isMobile() === true ? 24 : 12,
                        padding: Utils.isMobile() ? 24 : '3vw',
                        width: '100%'
                    }}>
                        <img 
                        src={`images/${platform}-mobile-app-support-icon.png`}
                        style={{
                            height: 175,
                            objectFit: 'contain',
                            width: 175
                        }} />
                    </div>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(400),
                        fontSize: 18
                    }}>{`We're sorry to hear that you've run into an issue while using one of our mobile apps. Please fill out your information below and we will have a customer service representative reach out to you with next steps. Our customer service teams typically respond to inquiries within 24-48 hours.`}</span>
                </div>
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: Utils.isMobile() ? 0 : '3vw',
                paddingLeft: Utils.isMobile() ? 0 : '3vw',
                paddingRight: Utils.isMobile() ? 0 : '3vw',
                paddingTop: Utils.isMobile() === true ? 0 : 0,
                width: '100%',
                ...Utils.isMobile() === false && {
                    paddingBottom: '3vw',
                    paddingLeft: '3vw',
                    paddingRight: '3vw',
                    paddingTop: 0,
                }
            }}>
                <iframe 
                src={`${API.server}/support/?id=15&dealership_id=${dealership.id}&dealership_access_token=${dealership.access_token}`}
                style={{
                    border: 'none',
                    height: height,
                    width: '100%'
                }} />
            </div>
        </div>
    )
}

export const NetworkSolutions = ({ dealership, utils }) => {

    const onAppDownloadClick = platform => {
        let omnishield = utils.dealership.omnishield.get();
        switch(platform) {
            case 'android':
            window.open(omnishield && omnishield.android.play_store_url || 'https://play.google.com/store/apps/details?id=com.commlink.omnishield2');
            break;

            case 'ios':
            window.open(omnishield && omnishield.ios.app_store_url || 'https://apps.apple.com/gb/app/omnishield/id1491369651');
            break;
        }
    }

    const getItemContainer = (key, orientation) => {

        // prepare list of container items
        let items = {
            bed_shakers: {
                description: 'Our number one goal is to alert you of danger at all times and help you pinpoint where the danger is. Our network uses multiple alerts while you sleep. We use the most effective waking sound coupled with vibrating your mattress to ensure you wake up and get to safety in time.',
                features: ['520 Hz Square Wave Cone Speaker', 'Vibrating puck', 'HD LED Icons', 'RF Frequency wireless connectivity', 'Bedside Control Center for EPS', 'Silence and testing mode button', '3 Duracell Lithium batteries', 'More effective at waking children, adults over 40, hearing impaired and those who are inhibited with sleep aids or alcohol'],
                image: 'omnishield-bed-shaker.jpg',
                title: 'Bedside Control Center'
            },
            co_sensors: {
                description: 'No home should be without carbon monoxide monitoring. CO is completely odorless, colorless and undetectable by humans. Our network detects this silent killer and alerts you in multiple ways before lethal levels occur.',
                features: ['Most advanced electro-chemical CO sensor available', 'Fastest responding CO alarm on the market', 'Quartz Crystal Oscillator', 'Texas Instruments microprocessor-controlled', 'Proprietary wireless connection', 'One touch silence feature', '100 db Piezo Horn', 'Rate-of-Rise with Dual Differentiation Technology', '94 VO flame rated to UL Standards', 'Meets 10-Year tamper resistant requirement with an industrial LiMn sealed battery', '200 foot radio frequency range', 'Self diagnostics', 'Lifetime fire replacement warranty', 'Lifetime replacement guarantee'],
                image: 'omnishield-co-sensor.jpg',
                placement: 'omnishield-co-sensor-proper-placement.png',
                title: 'Carbon Monoxide Sensors'
            },
            heat_sensors: {
                description: `Rooms such as attics, laundry rooms and garages are not ideal for smoke alarms due to the activities performed in that room.  This leaves gaps in your home's fire safety. For these areas, our network's heat sensor helps you leave no room to chance when it comes to a fire.`,
                features: [
                    'Rate-of-Rise with Dual Differentiation Technology', 'Fixed Temperature with 3 programmable settings', '(117° 135° 175°)', 'Quartz Crystal Oscillator', 'Proprietary wireless connection', 'One touch silence feature', '100 dB Piezo Horn', '94 VO flame rated to UL Standards', 'Meets 10-Year tamper resistant requirement with an industrial LiMn sealed battery', 'Illuminated HD LED icon for warnings and alerts', '200 foot radio frequency range', 'Self diagnostics', 'Lifetime fire replacement warranty', 'Lifetime replacement guarantee'
                ],
                image: 'omnishield-heat-sensor.jpg',
                placement: 'omnishield-heat-sensor-proper-placement.jpg',
                title: 'Heat Sensors'
            },
            mobile_app: {
                content: (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <div 
                        className={'text-button'}
                        onClick={onAppDownloadClick.bind(this, 'ios')}
                        style={{
                            backgroundColor: 'black',
                            borderRadius: 30,
                            marginRight: 4,
                            padding: 4,
                            width: '50%'
                        }}>
                            <img 
                            src={'images/app-store-badge.png'}
                            style={{
                                height: 40,
                                objectFit: 'contain',
                                width: '100%'
                            }} />
                        </div>
                        <div 
                        className={'text-button'}
                        onClick={onAppDownloadClick.bind(this, 'android')}
                        style={{
                            backgroundColor: 'black',
                            borderRadius: 30,
                            marginLeft: 4,
                            padding: 4,
                            width: '50%'
                        }}>
                            <img 
                            src={'images/play-store-badge.png'}
                            style={{
                                height: 40,
                                objectFit: 'contain',
                                width: '100%'
                            }} />
                        </div>
                    </div>
                ),
                description: `Our network's proprietary app that let's you see your home's safety in real time. Disastrous fires and floods can cause expensive damage. Our app alerts you before anyone else so you can make sure your loved ones are safe and your home is protected.`,
                features: ['Add up to 8 contacts', 'Contact Syncing', 'Remote Monitoring', 'Text Messages, Email Notifications, and Push Notifications', 'Travel Time Map', 'PEEK In Feature', 'Available for iOS and Android'],
                image: 'mobile-app-mockup.jpg',
                title: 'Mobile App',
                waypoint: 'mobile_app'
            },
            smoke_sensors: {
                description: 'Many smoke detectors installed in homes today are unreliable due to dead or missing batteries, improper placement, dust buildup or simple age. Our network uses two different technologies to detect slow smoldering and fast flaming fires with less false alarms. Our optical smoke sensor is housed in a chamber which can be removed and cleaned.',
                features: ['Quartz Crystal Oscillator', '360° unobstructed smoke chamber', 'Dual differentiation thermal sensors', 'Proprietary wireless connection', 'One touch silence feature', '100 dB Piezo Horn', '94 VO flame rated to UL Standards', 'Infrared optics scanning chamber every 4 seconds', 'Meets 10-Year tamper resistant requirement with an industrial LiMn sealed battery', '200 foot radio frequency range', 'Self diagnostics', 'Lifetime fire replacement warranty', 'Lifetime replacement guarantee'],
                image: 'omnishield-smoke-sensor.jpg',
                placement: 'omnishield-smoke-sensor-proper-placement.jpg',
                title: 'Smoke Sensors'
            },
            water_sensors: {
                description: '37% of homes have claimed water damage in their insurance & over 14,000 people experience water damage in their homes or at work every day. The average washing machine hose will leak every 8.7 years and 75% of water heaters will fail in their 12th year.',
                features: ['23 KT Gold Sensor', 'Long Life Lithium Battery', 'Piezo horn locator', 'HD LED Icons', 'Test Button/Silence feature', 'Email & Text Alerts', 'PEEK Feature (24 hours per day)'],
                image: 'omnishield-water-sensor.jpg',
                title: 'Water Sensors'
            }
        }

        // prepare content child components
        let content = (
            <div className={`col-12 col-lg-6 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-2' : 'pl-lg-2 pr-lg-4'} py-lg-2`}>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                    marginBottom: 8
                }}>{items[key].title.toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 18,
                    marginBottom: 24
                }}>{items[key].description}</span>
                {getFeatureContainer(items[key].features)}
                {items[key].placement && getPlacementContainer(items[key].placement)}
                {items[key].content}
            </div>
        )

        // prepare image child components
        let image = (
            <div className={`col-12 col-lg-6 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-4' : 'pl-lg-4 pr-lg-2'} py-lg-2`}>
                <img 
                src={`images/${items[key].image}`}
                style={{
                    borderRadius: 8,
                    height: 'auto',
                    objectFit: 'cover',
                    width: '100%'
                }} />
            </div>
        )

        // determine if a right orientation layout was requsted
        if(orientation === 'right' && Utils.isMobile() === false) {
            return (
                <div
                {...items[key].waypoint && { id: `navigation_component_${items[key].waypoint}` }}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    width: '100%'
                }}>
                    <div 
                    className={'row m-0'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        maxWidth: 1250,
                        padding: '3vw',
                        width: '100%'
                    }}>
                        {content}
                        {image}
                    </div>
                </div>
            )
        }

        // fallback to returning a left orientation layout
        return (
            <div
            {...items[key].waypoint && { id: `navigation_component_${items[key].waypoint}` }} 
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24,
                width: '100%'
            }}>
                <div 
                className={'row m-0'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    maxWidth: 1250,
                    padding: '3vw',
                    width: '100%'
                }}>
                    {image}
                    {content}
                </div>
            </div>
        )
    }

    const getFeatureContainer = features => {
        return (
            <LayerItem 
            collapsed={false}
            headerTitleStyle={{
                fontFamily: Appearance.fonts.getFamilyForWeight(800),
                fontSize: 24,
                fontWeight: null
            }}
            title={'FEATURES'}>
                <ul style={{
                    paddingInlineStart: 24
                }}>
                    {features.map((feature, index) => {
                        return (
                            <li 
                            key={index} style={{
                                color: Appearance.colors.text(),
                                fontFamily: Appearance.fonts.getFamilyForWeight(400),
                                fontSize: 18
                            }}>{feature}</li>
                        )
                    })}
                </ul>
            </LayerItem>
        )
    }

    const getPlacementContainer = image => {
        return (
            <LayerItem 
            collapsed={true}
            headerTitleStyle={{
                fontFamily: Appearance.fonts.getFamilyForWeight(800),
                fontSize: 24,
                fontWeight: null
            }}
            title={'PROPER PLACEMENT'}>
                <img 
                src={`images/${image}`}
                style={{
                    border: `1px solid ${Appearance.colors.divider()}`,
                    borderRadius: 8,
                    height: 'auto',
                    objectFit: 'contain',
                    width: '100%'
                }} />
            </LayerItem>
        )
    }

    const getHeroImage = () => {

        // determine if mobile layout was requested
        if(Utils.isMobile() === true) {
            return (
                <div 
                id={'navigation_component_home'}
                style={{
                    alignItems: 'center',
                    backgroundImage: 'url(images/mobile-header-background.jpg)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 36,
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        color: 'white',
                        fontFamily: Appearance.fonts.getFamilyForWeight(800),
                        fontSize: 36,
                        lineHeight: 1.2,
                        marginBottom: 18
                    }}>{'THE HOME SAFE NETWORK'}</span>
                    <span style={{
                        color: 'white',
                        fontFamily: Appearance.fonts.getFamilyForWeight(500),
                        fontSize: 18,
                        marginBottom: 24
                    }}>{'Designed and engineered in the United States, our network incorporates Texas Instruments micro-processors and RF modules to give your home a private network of wireless sensors, so if there is danger in one room, you are alerted in every room of the home.'}</span>
                </div>
            )
        }
        return (
            <div 
            id={'navigation_component_home'}
            style={{
                position: 'relative',
                width: '100%'
            }}>
                <img 
                src={'images/omnishield-splash-05.jpg'}
                style={{
                    height: 'auto',
                    maxHeight: 1000,
                    objectFit: 'cover',
                    width: '100%'
                }} />
                <div style={{
                    alignItems: 'center',
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'flex-start',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '50%',
                        padding: '5vw'
                    }}>
                        <span style={{
                            color: 'white',
                            fontFamily: Appearance.fonts.getFamilyForWeight(800),
                            fontSize: 36,
                            marginBottom: 8
                        }}>{'THE HOME SAFE NETWORK'}</span>
                        <span style={{
                            color: 'white',
                            fontFamily: Appearance.fonts.getFamilyForWeight(400),
                            fontSize: 18,
                            maxWidth: 1000
                        }}>{'Designed and engineered in the United States, our network incorporates Texas Instruments micro-processors and RF modules to give your home a private network of wireless sensors, so if there is danger in one room, you are alerted in every room of the home.'}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '5vw',
            width: '100%'
        }}>
            {getHeroImage()}
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                padding: '5vw',
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <img 
                    src={'images/vector-sensor-group.png'}
                    style={{
                        height: 'auto',
                        marginBottom: Utils.isMobile() === true ? 24 : 36,
                        maxWidth: 500,
                        objectFit: 'contain',
                        width: '100%'
                    }} />
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontFamily: Appearance.fonts.getFamilyForWeight(800),
                        fontSize: 24,
                        marginBottom: 16
                    }}>{'Event Positioning System'.toUpperCase()}</span>
                    
                    <span style={{
                        color: Appearance.colors.text(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(400),
                        fontSize: 18,
                        maxWidth: 1000
                    }}>{'Our network helps you detect which room the danger is coming from so you and your loved ones can escape quickly and safely. Powered by sealed LiMn industrial batteries, you can trust our network to be there when you least expect it but need it most.'}</span>
                </div>
            </div>
            <div style={{
                paddingLeft: '5vw',
                paddingRight: '5vw'
            }}>
                {getItemContainer('mobile_app', 'left')}
                {getItemContainer('smoke_sensors', 'right')}
                {getItemContainer('heat_sensors', 'left')}
                {getItemContainer('co_sensors', 'right')}
                {getItemContainer('water_sensors', 'left')}
                {getItemContainer('bed_shakers', 'right')}
            </div>
        </div>
    )
}

export const OmniShieldAppHeader = ({ utils }) => {

    const ref = useRef(null);

    const onContactClick = () => {
        utils.navigation.set({ view: 'home', waypoint: 'contact' });
    }

    const getContent = () => {

        // determine if mobile layout was requested
        if(Utils.isMobile() === true) {
            return (
                <div 
                id={'navigation_component_home'}
                ref={ref}
                style={{
                    alignItems: 'center',
                    backgroundImage: 'url(images/family-sales-mobile.jpg)',
                    backgroundPosition: 'center',
                    backgroundBlendMode: 'lighten',
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 36,
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        color: '#232323',
                        fontFamily: Appearance.fonts.getFamilyForWeight(800),
                        fontSize: 36,
                        lineHeight: 1.2,
                        marginBottom: 18
                    }}>{'LEAVE NO ROOM TO CHANCE'}</span>
                    <span style={{
                        color: '#232323',
                        fontFamily: Appearance.fonts.getFamilyForWeight(500),
                        fontSize: 18,
                        marginBottom: 24
                    }}>{'Our mission is to educate families about the devastating effects of home fires and other home safety issues. Our seminars and one-on-one counseling teach families how to escape and survive the ravages of home fires, carbon monoxide poisoning and in some cases, even prevent property damage.'}</span>
                    <Button 
                    label={'SCHEDULE YOUR CONSULTATION'}
                    onClick={onContactClick}
                    outline={true}
                    outlineColor={'#232323'}
                    style={{
                        height: 45,
                        maxWidth: 300
                    }}
                    type={'large'} />
                </div>
            )
        }

        return (
            <div 
            id={'navigation_component_home'}
            ref={ref}
            style={{
                position: 'relative',
                width: '100%'
            }}>
                <img 
                src={'images/family-sales.jpg'}
                style={{
                    height: 'auto',
                    maxHeight: 1000,
                    objectFit: 'cover',
                    width: '100%'
                }} />
                <div style={{
                    alignItems: 'center',
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'flex-start',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '50%',
                        padding: '5vw'
                    }}>
                        <span style={{
                            color: '#232323',
                            fontFamily: Appearance.fonts.getFamilyForWeight(800),
                            fontSize: 36,
                            marginBottom: 8
                        }}>{'LEAVE NO ROOM TO CHANCE'}</span>
                        <span style={{
                            color: '#232323',
                            fontFamily: Appearance.fonts.getFamilyForWeight(400),
                            fontSize: 18,
                            marginBottom: 24,
                            maxWidth: 750
                        }}>{'Our mission is to educate families about the devastating effects of home fires and other home safety issues. Our seminars and one-on-one counseling teach families how to escape and survive the ravages of home fires, carbon monoxide poisoning and in some cases, even prevent property damage.'}</span>
                        <Button 
                        label={'SCHEDULE YOUR CONSULTATION'}
                        onClick={onContactClick}
                        outline={true}
                        outlineColor={'#232323'}
                        style={{
                            height: 45,
                            maxWidth: 300
                        }}
                        type={'large'} />
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if(ref.current) {
            utils.navigation.setWaypointObserver(ref.current, 'home');
        }
    }, [ref.current]);

    return getContent();
}

export const Prevention = () => {

    const getHeroContent = () => {
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    maxWidth: 1250,
                    padding: 24,
                    width: '100%'
                }}>
                    <img 
                    src={'images/prevention.jpg'}
                    style={{
                        borderRadius: 10,
                        height: 'auto',
                        marginBottom: 24,
                        objectFit: 'contain',
                        width: '100%'
                    }} />
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontFamily: Appearance.fonts.getFamilyForWeight(800),
                        fontSize: 24,
                    }}>{('"An ounce of prevention is worth a pound of cure."').toUpperCase()}</span>
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontFamily: Appearance.fonts.getFamilyForWeight(800),
                        fontSize: 24,
                        marginBottom: 16
                    }}>{'- Benjamin Franklin'.toUpperCase()}</span>
                    <span style={{
                        color: Appearance.colors.text(),
                        display: 'block',
                        fontFamily: Appearance.fonts.getFamilyForWeight(400),
                        fontSize: 18,
                        maxWidth: 1000
                    }}>{`Today's family faces many dangers inside their home. Experts tell us, that open floor plans, synthetic building materials, and increased fuel load in the modern home, are all reasons we can have as little as 3 minutes to escape a home fire.`}</span>
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24,
                maxWidth: 1250,
                padding: '3vw',
                textAlign: 'center',
                width: '100%'
            }}>
                <img 
                src={'images/prevention.jpg'}
                style={{
                    borderRadius: 10,
                    height: 350,
                    marginBottom: 24,
                    objectFit: 'contain',
                    width: 'auto'
                }} />
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                }}>{('"An ounce of prevention is worth a pound of cure."').toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                    marginBottom: 16
                }}>{'- Benjamin Franklin'.toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 18,
                    maxWidth: 1000
                }}>{`Today's family faces many dangers inside their home. Experts tell us, that open floor plans, synthetic building materials, and increased fuel load in the modern home, are all reasons we can have as little as 3 minutes to escape a home fire.`}</span>
            </div>
        )
    }

    const getItemContainer = (key, orientation) => {

        // prepare list of container items
        let items = {
            health: {
                description: `In addition, carbon monoxide, known as the silent killer, is odorless, tasteless, and invisible. CO poisoning leads to hundreds of deaths and tens of thousands of hospitalizations each year. And that's not all. Water leaks can cause property damage and even health problems from mold and mildew.`,
                image: 'images/health_and_wellness.jpg',
                title: 'Health and Wellness'
            },
            disasters: {
                description: `One solution is being prepared. Simply, knowing what to do before, during, and after an emergency can be the difference in possibly saving your property, and ensuring your family's health and safety. Saving our property prevents financial hardship and a horrific disruption to our lives. Saving our lives and the lives of our loved ones is priceless.`,
                image: 'images/disasters.jpg',
                title: 'When disasters strike, time can be a killer.'
            }
        }

        // prepare content child components
        let content = (
            <div className={`col-12 col-lg-7 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-2' : 'pl-lg-2 pr-lg-4'} py-lg-2`}>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: 24,
                    marginBottom: 8
                }}>{items[key].title.toUpperCase()}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 18,
                    marginBottom: 24
                }}>{items[key].description}</span>
            </div>
        )

        // prepare image child components
        let image = (
            <div className={`col-12 col-lg-5 p-2 ${orientation === 'left' ? 'pl-lg-2 pr-lg-4' : 'pl-lg-4 pr-lg-2'} py-lg-2`}>
                <img 
                src={items[key].image}
                style={{
                    borderRadius: 8,
                    height: 'auto',
                    objectFit: 'cover',
                    width: '100%'
                }} />
            </div>
        )

        // determine if a right orientation layout was requsted
        if(orientation === 'right' && Utils.isMobile() === false) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 24,
                    width: '100%'
                }}>
                    <div 
                    className={'row m-0'}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        maxWidth: 1250,
                        padding: '3vw',
                        width: '100%'
                    }}>
                        {content}
                        {image}
                    </div>
                </div>
            )
        }

        // fallback to returning a left orientation layout
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24,
                width: '100%'
            }}>
                <div 
                className={'row m-0'}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    maxWidth: 1250,
                    padding: Utils.isMobile() ? 24 : '3vw',
                    width: '100%'
                }}>
                    {image}
                    {content}
                </div>
            </div>
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5vw',
                width: '100%'
            }}>
                {getHeroContent()}
                {getItemContainer('health', 'right')}
                {getItemContainer('disasters', 'left')}
            </div>
        </div>
    )
}
